import React from 'react'
import Image from 'react-bootstrap/Image'
import image from '../../images/sauvegarde.jpeg'
import inf from '../../images/help.jpg'
import inf1 from '../../images/2.jpg'
import { Container, Row, Col } from 'react-grid-system'
import './Support.css'
function Support() {
    return (
        <div className='support' id='#support'>
            <Image src={image} style={{ 'width': '100%', 'height': '400px' }} />
            <Container>
                <Row>
                    <Col>
            <br />
                <br />
            <h1><b>SUPPORT</b></h1>
            </Col>
                </Row>
                </Container>
            <hr />

            <Container>
                <Row>
                    <Col>
                
            <p> 
                La base de données est le cœur technique de votre entreprise. Notre mission
                consiste à maintenir votre base de données IBM Informix et à garantir la
                disponibilité pour servir vos clients et vos processus métier sans aucun
                problème.
                <br />
                <br />
                Nous fournissons les services de base de données dont vous avez besoin,
                en fonction de vos besoins.</p>
                </Col>
                </Row>
                </Container>
            <div className='par1'>
                <Container>
                    <Row>
                        <Col sm={9}>
                            <br />
                            <br />
                            <h4>New System Engineering vous aide à:</h4>
                            <br />
                            <ul>
                                <li>Adaptater des paramètres de configuration</li>
                                <br />
                                <li>Implémenter des nouveaux services fournis avec le serveur Informix</li>
                                <br />
                                <li>Vérifier la structure et le traitement d’Informix</li>
                                <br />
                                <li>Analyser des sujets Informix pertinents</li>
                                <br />
                                <br />
                            </ul>
                        </Col>
                        <Col md={3}>
                            <br/>
                            <Image src={inf} style={{ 'width': '450px', 'height': '300px'}} />
                            <br/>
                            <br/>
                        </Col>
                    </Row>
                </Container>
            </div>
            <br/>
            <br/>
            <Container>
                <Row>
                    <Col md={10}>
            <h4>Support standard IBM – si vous avez besoin du service de base seulement</h4>
            <br />
            <ul>
                <li>Est acheté automatiquement pour une période minimale de 12 mois lors de l’achat du logiciel IBM Informix (support produit)</li>
                <br />
                <li>Ne couvre que les défaillances logicielles causées par l’éditeur</li>
                <br />
                <li>Inclut les mises à jour, correctifs, corrections de bugs et Hotline (8X5) pour les versions Informix supportées</li>
                <br />
            </ul>
            </Col>
                    </Row>
                </Container>
                <Container>
                <Row>
                    <Col>
                    <p>En fonction de vos besoins, nous fournissons les services de maintenance IBM Informix complémentaires suivants:</p>
                    </Col>
                </Row>
                </Container>
            <br/>
            <div className='par1'>
                <Container>
                    <Row>
                    <Col sm={9} >
                        <br /><br />
                        <h4>Support avancé – si vous avez besoin de plus que le standard</h4>
                        <br />
                        <ul>
                            <li>Condition préalable: contrat de support produit existant (support standard)</li>
                            <br />
                            <li>Est acheté en option pour une période minimale de 12 mois</li>
                            <br />
                            <li>Comprend le traitement des questions relatives à l’installation, à la configuration, à l’optimisation de la base de données</li>
                            <br />
                            <li>Consultation à distance autant que possible</li>
                            <br />
                            <li>Assistance sur site à facturer par jour</li>
                            <br />
                            <br />
                        </ul>
                    </Col>
                        <Col md={3}>
                        <br /><br />
                            <Image src={inf1} style={{ 'width': '450px', 'height': '300px' }} />
                        </Col>
                        
                </Row>
            </Container>
        </div>
        <br/>
        <br/>
        <Container>
                <Row>
                    <Col md={10}>
        <h4>Support individuel – si vous avez besoin d’un support spécial</h4><br /><ul>
        <li>est disponible mensuellement – idéal pour la demande de support de base de données survenant à court terme et / ou pour des périodes limitées (par exemple, vacances, maladie, pics)</li>
        <br />
        <li>Peut être réalisé à distance ou sur site</li>
        <br />
        </ul>
        </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Support
