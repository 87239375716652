import React from 'react'
import { Container, Row, Col,  } from 'react-grid-system';
import image1 from '../images/pres1.jpg'
import './Presentation.css'
function Presentation() {
    return (
        <div className='pres1'>
            
           
           <Container className='cont'>

           
               <Row>
                   <Col md={6} sm={12} xs={12}>
                    <span className='text1'> <h1>Qui sommes-nous ?</h1></span>
                    <span className='text2'>  <p>New System Engineering est une société d’ingénierie des systèmes 
                            d’information. Créée en 2015 pour gérer l’informatique d’un grand groupe de 
                            logistique et d’industrie (H&S Holding) : Dislog, CS, EDITA, ...
                            <br/>
                            A partir de 2017, New System Engineering s’est séparée du holding H&S et 
                            s’est spécialisée dans la gouvernance des données : Qualité des données, 
                            Sécurité des données, Analyse des données, Administration et optimisation des 
                            bases de données, …
                            <br/>
                            New System Engineering est officiellement partenaire<b> d’IBM</b> à partir de 2018 
                            et de <b>SAP</b> à partir de 2019.</p></span>
                    </Col>
                    <Col md={1}>
                    </Col>
                    <Col md={5} sm={12} xs={12}>
                        <img src={image1} style={{'width':'100%', 'height':'350px'}}/>
                    </Col>
                </Row>
            </Container> 
        </div>
    )
}

export default Presentation
