import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import img from '../../images/informix_software.png'

function Iwa() {
    return (
        <div>
            <hr/>
            <br/>
            <h1><b>INFORMIX WAREHOUSE ACCELERATOR (IWA)</b></h1>
            <hr />  
            <br/>          
            <Container>
              <Row>
                  <Col md={12}>
                  <center><img src={img} style={{'width':'350px','height':'150px'}}/></center>
                  <br/><br/>
                  </Col>
                </Row>
            </Container>
            <Container>
              <Row>
                  <Col md={12}>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>
                  Depuis 2011, IBM propose un puissant adonne à la base de données : Informix Warehouse pour l’édition Workgroup et Enterprise. C’est pour permettre aux utilisateurs d’Informix de bénéficier de l’analyse de l’entrepôt de données en quelques secondes.<br/>
                    Une requête avec un temps de réponse extrêmement court sans besoin de vues ou d’index supplémentaires est désormais possible.<br/>
                    Informix Warehouse comprend les composants suivants:<br/>
                      </p><br/>
                      <ul>
                          <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Serveur de base de données Informix</li><br/>
                          <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Outil d’entreposage</li><br/>
                          <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Informix Warehouse Accelerator (IWA)</li><br/>
                          <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Smart Analytics Optimizer Studio</li><br/>
                      </ul>
                      <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>New System Engineering vous donne un support complet pour l’examen de vos besoins, dimensionnement de la solution, l’installation des composants Warehouse, l’optimisation des licences, la maintenance et le support et aussi des ateliers de présentation et de formation.</p>
                  </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Iwa
