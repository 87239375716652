import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import img from '../../images/informix_software.png'

function Dataserver() {
    return (
        <div>
             <hr/>
            <br/>
            <h1><b>IBM INFORMIX DATABASE SERVER</b></h1>
            <hr />  
            <br/>          
            <Container>
              <Row>
                  <Col md={12}>
                  <center><img src={img} style={{'width':'350px','height':'150px'}}/></center>
                  <br/><br/>
                  </Col>
                </Row>
            </Container>
            <Container>
              <Row>
                  <Col md={12}>
                    <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX Advanced Enterprise Edition</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Inclut les fonctionnalités Informix Enterprise Edition, Informix Warehouse Accelerator (IWA) et la fonction Optimisation du stockage + Cognos BI et SPSS. Cette édition représente le summum de la performance et de l’évolutivité de l’entrepôt de données. Cette édition est disponible sur les versions 64 bits d’AIX, Solaris, HP-UX et Linux. Le composant IWA est disponible uniquement pour Linux basé sur Intel.</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX Enterprise Edition</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Inclut presque toutes les fonctionnalités Informix sur toutes les plateformes supportées (Linux, Unix, Windows, Mac) pour le déploiement et la distribution avec une évolutivité illimitée. L’édition Enterprise d’Informix inclut des fonctionnalités de cluster et de grille complètes et flexible. La compression du stockage (fonction d’optimisation du stockage) est disponible en tant que fonctionnalité facultative, laquelle peut entraîner des coûts. Informix Warehouse Accelerator (IWA), Cognox BI et SPSS ne sont pas inclus. Il n’y a aucune restriction dans les ressources matérielles (RAM, sockets, cœurs).</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX Workgroup Edition/Advanced Workgroup Edition</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Fournit de puissantes fonctionnalités de gestion des données, en particulier pour les entreprises de taille moyenne, y compris les nœuds de cluster de réplication (ER) illimités et les options de haute disponibilité (HDR, RSS, SDS) jusqu’à 2 secondaires. Informix Workgroup Edition est limité à 16 cœurs sur un maximum de 4 sockets et 16 Go de mémoire. Informix Workgroup Edition permet un stockage de données illimité.<br/><br/>
                    Construit autour de la même technologie Informix Warehouse Accelerator disponible avec Informix Advanced Enterprise Edition, l’offre Informix Advanced Workgroup Edition offre des performances et des capacités comparables à celles du mid-market, mais n’offre aucune compression de données. Le composant IWA est disponible uniquement pour Linux basé sur Intel. Le Growth Warehouse peut être mis sur une machine qui a jusqu’à 16 cœurs et 48 Go de RAM, ce qui permet aux clients d’accélérer la taille des entrepôts de données jusqu’à 250 Go ou parfois un peu plus, en fonction de la compression obtenue. Les Data Marts dépassant la taille de 250 Go a grandement besoin de l’édition Advanced Enterprise.</p><br/><br/>
                    <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX Express Edition</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Informix Express Edition est le système de gestion de base de données d’entrée de gamme pour les petites et moyennes entreprises. L’édition Express allie performance et fiabilité avec simplicité d’installation et de manipulation. Informix Express Edition offre un bon rapport qualité-prix. Informix Express Edition est disponible sur les plateformes Linux, Unix, Windows et Mac. L’utilisation de cette édition est limitée à max. 8 Go de mémoire et max. 4 cœurs. Données illimitées</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX Innovator C-Edition</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Innovator C-Edition est une base de données gratuite téléchargeable uniquement pour les clients finaux (sans ISV). Il permet aux petites entreprises d’utiliser la fonctionnalité de base de données pour des groupes de travail. Il est limité à 1 core, 2 Go de RAM et 8 Go de données. Peut être installé dans des environnements de production. Le support produit est facultatif et payant (support assuré par IBM).</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX  Developer Edition</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Une base de données gratuite qui inclut toutes les fonctionnalités Informix pour le développement et le test d’applications individuelles seulement (30 jours). Disponible sur plusieurs plateformes. Informix Developer Edition est limité à 20 utilisateurs, 1 cœur, 1 Go de RAM et 8 Go de données installées. Ne doit pas être installé dans des environnements productifs. Il est disponible sur une large gamme de systèmes d’exploitation en versions 32 et 64 bits. Il est possible de faire une mise à niveau de Developer Edition directement vers n’importe quelle autre édition en installant simplement les nouveaux fichiers binaires de la base de données.</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>Cycle de vie du support Informix</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>tes-vous sûr que votre version de base de données Informix réellement utilisée est toujours supportée par IBM? Votre base de données Informix est peut-être une version “end of support” (eos) qui n’est plus maintenue. Dans ce cas, le support de l’éditeur pourrait être très coûteux pour vous. Ci-dessous un aperçu des dates de “fin de support” des versions de base de données Informix courantes:</p><br/><br/>
                  <ul>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 7.30  – 3/2003</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 7.31 –   9/2009</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 9.21 –   6/2004</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 9.40 –   3/2003</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 10.00 – 9/2010</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 11.10 – 9/2012</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>IDS 11.50 – 3/2018</li><br/>
                  </ul>
                  </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Dataserver
