import React from 'react'
import card from '../../images/analy.jpg'
import Image from 'react-bootstrap/Image'
import './Analyse.css';
import { Container,Row, Col } from 'react-bootstrap';

function Analyse() {
    return (
        <div classeName='analyse' id='#aa'>
            <Image src={card} style={{ 'width': '100%', 'height': '400px' }} />
            <br/>
            <br/>
            <br/>
            <h1><b>ANALYSE ET OPTIMISATION DES PERFORMANCES</b></h1>
            <hr />
            <Container>
                <Row>
                    <Col>
            <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
            Vous estimez que les performances de votre base de données IBM Informix sont médiocres, que votre taux 
            de débit E/S de données est bas, que votre temps de réponse est long 
            et que vos temps d’arrêt sont trop coûteux.<br/> Si c’est le cas, il est grand temps d’en rechercher les raisons
            Mieux vaut le faire à l’avance – avant de souffrir des problèmes mentionnés ci-dessus.<br/>
            Nous vous soutenons en vous assurons les prestations suivantes :
            </p>
            <br/>
            <ul>
                <li>
                Une configuration optimale du système
                </li>
                <br/>
                <li>
                Une distribution adéquate des données
                </li>
                <br/>
                <li>
                Une amélioration des temps de réponse
                </li>
            </ul>
            <br/>
            <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
            Après la finalisation de l’analyse de la base de données IBM Informix, vous recevez un rapport 
            résumant les résultats et les suggestions d’optimisation de la base de données qu’on propose 
            d’appliquer en tenant compte de toutes vos contraintes de production.
            </p>
            </Col>
            </Row>
            </Container>
        </div>
    )
}

export default Analyse
