import React from 'react'
import Image from 'react-bootstrap/Image'
import image from '../../images/formation.jpg'
import { Container, Row, Col } from 'react-grid-system'
import './Formations.css'
import logo from '../../images/informix_software.png'

function Formations() {
    return (
        <div className='formation'>
          <Image src={image} style={{ 'width': '100%', 'height': '400px' }} /><br/>
          <h1><b>FORMATIONS IBM INFORMIX</b></h1><br/>
          <hr />  
          <br/>
          <br/>
          <Container className='for1' >
              <Row>
                  <Col md={5}>
                  <h3>Développement d’Application avec <b>IBM INFORMIX</b> 4GL</h3><br/>
                    <h5>Description</h5>
                    
                    <br/>
                    <p>Les programmeurs se familiariseront avec les outils de base pour développer des applications à l’aide du langage de programmation Informix 4GL.

                    Ils découvriront les outils de développement et de débogage offerts, ainsi que la manière de concevoir et de compiler des applications dans l’environnement du programmeur 4GL.

                    Ils apprendront comment créer et utiliser des formulaires à une et à plusieurs lignes, générer des rapports et gérer les transactions et les curseurs.

                    Ils créeront aussi des applications et ajouteront des fonctionnalités supplémentaires au fur et à mesure que les nouveaux concepts seront présentés.</p>
                    <br/>
                    <h5>Matériel de cours</h5>
                    <br/>
                    <p>Informix 4GL 7.50.xC3.</p>
                    <h5>Durrée</h5>
                    <br/>
                    <p>5 jours</p>
                  </Col>
                  <br/>
                  
                  <Col md={1}></Col>
                  <Col md={5}>
                    <h3><b>INFORMIX</b> 11.7 System Administration</h3><br/>
                    <h5>Description</h5>
                    <br/>
                    <p>Informix 11.7 system administration dans ce cours de 5 jours, les participants développeront des compétences nécessaires pour administrer un ou plusieurs serveurs de bases de données.<br/>Vous apprendrez comment configurer et initialiser une instance de serveur de base de données, à configurer et à tester la connectivité client, à configurer et à gérer la mémoire et l’utilisation du disque, à planifier et à implémenter des tâches de maintenances
                     systèmes, et à configurer le serveur pour une utilisation optimale d’Online Transaction Processing (OLTP).</p> <br/>
                    <h5>Matériel de cours </h5><br/>
                    <p>Ce cours est basé sur IBM Informix version 11.7</p>
                    <h5>Durrée</h5>
                    <br/>
                    <p>5 jours</p>
                  </Col>
              </Row>
          </Container>
          <br/>
          <br/>
          <hr/>
          <br/>
          <br/>
          <Container className='for1'>
              <Row>
                  <Col md={5}>
                    <h3><b>INFORMIX</b> 11.7 Database Administration</h3><br/>
                    <h5>Description</h5>
                    <br/>
                    <p>Cette formation permet aux participants d’acquérir les connaissances et compétences nécessaires pour déterminer les types de données adéquates, pour créer, gérer et maintenir les tables et les index,
                     pour gérer les données, pour utiliser la fonctionnalité SET EXPLAIN pour évaluer l’efficacité de la requête…</p> <br/>
                    <br/>
                    <h5>Matériel de cours </h5><br/>
                    <p>Ce cours est basé sur IBM Informix version 11.7</p>
                    <h5>Durrée</h5>
                    <br/>
                    <p>5 jours</p>
                  </Col>
                  <Col md={1}></Col>
                  <Col md={5}>
                      <img src={logo} style={{'width':'80%','paddingTop':'200px','marginLeft':'40px'}} />
                  </Col>
              </Row>
          </Container>
          <br/>
          <br/>
          
        </div>
    )
}

export default Formations
