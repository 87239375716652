import React from 'react'
import {Container, Row, Col} from 'react-bootstrap'
import img from '../../images/informix_software.png'

function Informix4gl() {
    return (
        <div>
            <hr/>
            <br/>
            <h1><b>INFORMIX 4GL</b></h1>
            <hr />  
            <br/>          
            <Container>
              <Row>
                  <Col md={12}>
                  <center><img src={img} style={{'width':'350px','height':'150px'}}/></center>
                  <br/><br/>
                  </Col>
                </Row>
            </Container>

            <Container>
              <Row>
                  <Col md={12}>
                    <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX  4GL</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>IBM Informix 4GL est un environnement de développement et de production d’applications de quatrième génération qui fournit puissance et flexibilité sans recourir à des langages de troisième génération tels que C et COBOL. Le système de développement rapide Informix 4GL et le débogueur interactif Informix 4GL fournissent l’environnement optimal pour le développement d’applications, tandis que le compilateur C Informix 4GL fournit une exécution d’applications hautes performances dans l’environnement de production.</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX 4GL Rapid Development System (RDS)</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Un environnement de développement d’application avec des composants de programmes et qui compile les fichiers sources en code p (pseudocode) pour réduire le temps de développement de l’application.</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX 4GL Interactive Debugger</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Un ensemble d’outils que vous pouvez utiliser pour déboguer vos programmes pendant qu’ils sont en cours d’exécution. Vous pouvez interrompre les programmes en cours, modifier les valeurs des variables et reprendre les programmes avec des nouvelles valeurs.</p><br/><br/>
                    <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX 4GL C Compiler</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Un compilateur pour maximiser les performances des applications. Compile le code source Informix 4GL en code C et est principalement utilisé pour votre environnement de production.</p><br/><br/>
                  <h4 style={{'color':'rgb(105,105,105)', 'textDecoration':'underline' }}>INFORMIX 4GL 7.50 et les Web Services</h4>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Vous pouvez réutiliser le code I4GL existant pour publier, déployer et empaqueter des fonctions I4GL en tant que services Web. En outre, vous pouvez vous abonner aux services Web existants.Avec l’utilitaire w4gl, vous pouvez publier, déployer, empaqueter et vous abonner aux services Web I4GL via une interface d’application I4GL.</p><br/><br/>
                  <ul>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>La publication d’un service Web implique la spécification des composants du service Web, à savoir le nom du service Web, la fonction I4GL, les types de données d’entrée et de sortie et la liste des fichiers dont dépend cette fonction I4GL. Ces détails sont stockés dans un fichier de configuration avec une extension .4cf.</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Le déploiement d’un service Web compile le code Axis2, écrit les fichiers WSDL (Web Services Definition Language) et déplace les fichiers vers le serveur d’applications sur lequel le service Web sera exécuté.</li><br/>
                      <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>L’empaquetage d’un service Web déplace le service Web sur un serveur de production.</li><br/>
                      </ul>
                      <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>L’abonnement à un service Web se fait selon le service Web désigné.</p><br/><br/>

                  </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Informix4gl
