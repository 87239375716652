import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import Footer from '../Footer';
import Presentation from '../Presentation';
import Presentation2 from '../Presentation2';
import Carousal from '../Carousal';
import 'bootstrap/dist/css/bootstrap.min.css';
import Cardsimg from '../Cardsimg';



function Home() {
  return (
    <div id='#/'>
      <Carousal/>
      <Presentation/>
      <Presentation2/>

      <Cards />
      <Cardsimg/>
      
    </div>
  );
}

export default Home;
