import React from 'react'
import { Container, Row , Col} from 'react-bootstrap'
import img1 from '../../images/GE.png'
import img2 from '../../images/GM.png'
import img3 from '../../images/GR.png'

function Genero() {
    return (
        <div>
            <hr/>
            <br/>
            <h1><b>GENERO</b></h1>
            <hr />  
            <br/>
            <Container>
                <Row>
                    <Col md={5}>
                        <img src={img1} />
                        <br/><br/>
                        <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Une infrastructure de développement et déploiement conçue pour augmenter la productivité des développeurs et leur laisser du temps pour libérer leur énergie créatrice et innovatrice en mettant au point des applications</p>
                    </Col>
                    <Col md={2}></Col>
                    <Col md={5}>
                    
                    <img src={img3} />
                    <br/><br/>
                        <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Créez des mises en page riches à l’aide d’un concepteur de rapports intuitif qui prend en charge les fonctionnalités d’entreprise se trouvant dans des ordres de grandeur de produits plus coûteux; les en-têtes de pages et de groupes, les sauts de page intelligents, les totaux cumulés, les graphiques, les images, les polices, les codes à barres, les graphiques vectoriels et les tableaux.</p>
                    </Col>
                    </Row>
                    </Container>
                    <Container>
                <Row>
                <Col md={5}>
                <img src={img2} />
                <br/><br/>
                        <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>Réduisez votre «time-to-value» en créant de magnifiques applications natives pour iOS et Android avec un seul cycle de développement.</p>
                    </Col>
                </Row>
            </Container>
            <br/><br/>
            <Container style={{'backgroundColor':'rgb(220,220,220)', 'borderRadius': '28px'}}>
                <Row>
                    <Col>
                    <br/>
                    <center><p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>  Migration des applications Informix 4GL vers Genero : Nouvelle vie pour les applications IBM Informix 4GL</p></center>
                    </Col>
                </Row>
            </Container>
            <br/><br/>
            <br/><br/>
        </div>
    )
}

export default Genero
