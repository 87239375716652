import React from 'react'
import Image from 'react-bootstrap/Image'
import image from '../../images/img23.jpg'
import { Container, Row, Col } from 'react-grid-system'

function Sauvegardage() {
    return (
        <div className='sauvegarde'>
            <Image src={image} style={{ 'width': '100%', 'height': '400px' }} />
            <br/><br/>
            <h1><b>SAUVEGARDE / RESTAURATION</b></h1>
            <hr />
            <br/>
            <Container>
                <Row>
                    <Col>
                    <br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                    La sauvegarde Informix est utilisée pour enregistrer les données de votre entreprise pendant des semaines ou des mois. En cas de problèmes logiciels ou matériels ou de mauvaise manipulation des utilisateurs, vos données peuvent être restaurées si une stratégie de sauvegarde est déjà implémentée.<br/>
                    Nous vous indiquons les possibilités qu’IBM Informix Server peut vous offrir pour gérer votre entreprise de manière sécurisée. Par conséquent, nous vous supportons  dans les domaines suivants:<br/></p>                    
                    <br/>
                    <ul>
                        <li >Information</li>
                        <li >Conception</li>
                        <li >La mise en oeuvre</li>
                        <li >Exploitation</li>
                    </ul>
                    <br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>Ensemble, nous définissons vos besoins individuels pour la stratégie de sauvegarde appropriée : Réunion de travail ou atelier dédié.<br/>
                    En fonction de vos besoins, nous nous concentrerons sur le type, la taille et la fréquence de la solution de sauvegarde requise et proposerons des outils et des fonctionnalités Informix adaptés. Vous assurez que votre entreprise est sur la bonne voie, que vos systèmes sont disponibles en fonction des besoins.<br/></p><br/>
                    <h3>ARCHIVAGE</h3><br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>L’archivage représente l’extension logique de la sauvegarde à long terme. L’archivage réalisé dans une combinaison intelligente avec une stratégie Informix de sauvegarde répondant à la demande conduira à une parfaite gestion de la récupération des données.<br/>
                    Des règles légales et des instructions définies par l’entreprise régissent l’archivage des données sensibles. Notre objectif est de stocker vos données à long terme, à l’épreuve des audits et la transparence au fil des années et des décennies.<br/>
                    Nous sommes en mesure de vous proposer la solution IBM OPTIM Data Growth pour répondre à tous vos besoins d’archivage.<br/></p>
                    <br/><br/>
                    
                    </Col>
                </Row>
                </Container>
        </div>
    )
}

export default Sauvegardage
