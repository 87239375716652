import React from 'react'
import {Container, Col, Row} from 'react-bootstrap'
import card from '../../images/migration.jpg'
import image from '../../images/2.jpg'
import Image from 'react-bootstrap/Image'
import './Migration.css'

function Migration() {
    return (
        <div>
          <Image src={image} style={{ 'width': '100%', 'height': '400px' }} /><br/><br/>
            <h1><b>MIGRATION</b></h1>
            <hr />
            <br/>
            <Container>
                <Row>
                    <Col md={6}>
                    <br/><br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>Vous envisagez d’installer une nouvelle version du serveur de base de données Informix. Vous allez changer les composants logiciels et matériels en même temps. Vous souhaitez remplacer votre base de données actuelle par un serveur de base de données Informix.<br/>
                    Dans ce cas, vous devez migrer vos données. L’effort dépend de plusieurs et différents aspects, par exemple</p>
                    <ul>
                        <li >Version actuelle du serveur de base de données</li>
                        <li >Architecture et configuration de l’environnement du système</li>
                        <li >Intégration de logiciels tiers</li>
                        <li >Taille de la base de données</li>
                    </ul>
                    <br/><br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>Vis à vis des utilisateurs, l’impact de la migration doit être minimisé. <b>New System Engineering</b> vous soutient dans ce défi. Nous proposons les services suivants:</p>
                    <ul>
                        <li >Inventaire de votre environnement Informix</li>
                        <li >Evaluation des nouvelles exigences système</li>
                        <li >Évaluation des chemins d’accès de vos clients</li>
                        <li >Définition d’un horaire le plus approprié</li>
                        <li >Sélection du type de migration</li>
                        <li >Sélection de l’outil de migration le plus approprié</li>
                        <li >Planification des activités de migration</li>
                        <li >Vérification de la cohérence du système et des données après la migration</li>
                    </ul>
                    <br/><br/>
                    </Col>
                    <Col md={1}></Col>
                    
                    <Col md={5} >
                    <div className='migration'>
                    <br/><br/>
                        <img className='potr' src={card}/>
                    </div>
                    </Col>
                    
                </Row>
                </Container>
        </div>
    )
}

export default Migration
