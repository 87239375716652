import React from 'react'
import {Container, Row, Col } from 'react-bootstrap'
import img from '../../images/Data_Replication.png'

function Datareplication() {
    return (
        <div>
              <hr/>
            <br/>
            <h1><b>IBM INFOSPHERE DATA REPLICATION</b></h1>
            <hr />  
            <br/>          
            <Container>
              <Row>
                  <Col md={12}>
                      <center><img src={img} style={{'width':'350px','height':'350px'}}/></center>
                  </Col>
                </Row>
            </Container>
            <Container>
              <Row>
                  <Col >
                  <br/>   
                  <p style={{'font-family': 'Source Sans Pro, sans-serif','textAlign':'justify', 'textAlign':'justify'}}>
                  La réplication de données Informix (IDR / IIDR) permet une synchronisation fiable et puissante des données en tenant compte des mises à jour continues des données. Dans la plupart des cas, la réplication haute disponibilité des données (HDR), la restauration continue des journaux (CLR) et la réplication d’entreprise (CDR / ER) répondent parfaitement aux exigences de rapidité et de fiabilité.  Mais il existe des scénarios où HDR, CLR et CDR / ER ne sont pas ou pas adaptés pour déplacer des données d’un serveur à un autre:<br/><br/>
                    Vous utilisez différentes bases de données dans un environnement hétérogène. Par exemple. vous souhaitez déplacer des données d’une base de données Informix vers un serveur de base de données DB2, Oracle, MS SQL ou Sybase. Habituellement, ce défi ne peut être relevé que par une programmation individuelle poussée ou par la consolidation de votre environnement de base de données, ce qui signifie finalement l’achat de licences de base de données plutôt onéreuses.<br/><br/>
                    CDR / ER a besoin de trop de ressources du serveur de base de données. Cela entraîne des temps d’accès très longs aux données. Enfin, les performances de la base de données diminuent.<br/><br/>
                    Pour des raisons de disponibilité / sécurité, vous déconnectez le processus d’échange de données entre le serveur source et le serveur cible. Cela a du sens si vous souffrez des problèmes (non reproductibles) en utilisant CDR / ER pour l’échange de très gros volumes de données ou en utilisant un réseau instable.<br/><br/>
                    IBM InfoSphere Data Replication (IBM InfoSphere Change Data Capture), en abrégé CDC, est une réplication basée sur le journal que génère le serveur de base de données source. CDC est capable d’échanger des données dans des environnements hétérogènes entre différentes bases de données ou de remplacer la réplication Informix (HDR, CLR, CDR / ER) utilisée pour un volume de données élevé si la disponibilité et les performances sont un problème. Vos applications ne doivent pas être modifiées.<br/><br/>
                    IBM Infosphere Data Replication offre un large éventail d’options. Dans le scénario le plus simple, vous déplacez directement les données d’un serveur de base de données à un autre. Vous pouvez également échanger une table source directement dans une table cible. De plus, vous pouvez déplacer des données également par colonne. Vous pouvez combiner CDC avec les outils ETL et utiliser les options de filtre. Il est aussi possible de superviser les performances au niveau du serveur source et du serveur cible. Les goulots d’étranglement potentiels sont affichés sous forme d’alertes.<br/><br/>
                    IBM Infosphere Data Replication est installé et sous licence processeur sur le serveur de base de données source et cible.<br/><br/>
                    New System Engineering vous assure les services suivants :<br/><br/>
                  </p>
                  <ul>
                      <li>Conseil</li>
                      <li>Installation Configuration</li>
                      <li>Formation / Ateliers</li>
                  </ul>
                  </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Datareplication
