import React from 'react'
import card from '../../images/HA.jpg'
import Image from 'react-bootstrap/Image'
//import './HA.css';
import { Container,Row, Col } from 'react-bootstrap';

function HA() {
    return (
        <div classeName='analyse' id='#haute_disponibilite'>
            <Image src={card} style={{ 'width': '100%', 'height': '400px' }} />
            <br/>
            <br/>
            <br/>
            <h1><b>HAUTE DISPONIBILITE (HA)</b></h1>
            <hr />
            <Container>
                <Row>
                    <Col>
            <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
            Les systèmes de base de données doivent répondre à des exigences de sécurité vis à vis des échecs, de la disponibilité et de l’évolutivité des données qui augmentent en permanence. Les temps d’arrêt sont coûteux, endommagent votre image et menacent l’existence de votre entreprise.<br/>

            Par conséquent, les solutions de haute disponibilité sont essentielles pour les entreprises professionnelles et le traitement de leurs données sensibles. Les solutions à haute disponibilité d’Informix assurent la continuité de l’activité dans les scénarios suivants:
            </p>
            <br/>
            <ul>
                <li>
                Crash matériel
                </li>
                <br/>
                <li>
                Erreurs logiques au niveau de la base de données
                </li>
                <br/>
                <li>
                Faire face à des charges imprévues
                </li>
                <br/>
                <li>
                Effets externes (par exemple, feu, dégâts d’eau)
                </li>
            </ul>
            <br/>
            <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
            La sécurité ne signifie pas un coût très élevé. En raison de notre expérience dans le domaine de la haute disponibilité / reprise des désastres, nous sommes en mesure de vous offrir des solutions financièrement attractives et adaptées exactement pour ce dont vous avez besoin.<br/>

            Pour la réalisation des solutions Informix High Availability et Disaster Recovery, nous bénéficions d’un large éventail de possibilités techniques, marketing et de licences logicielles pour le serveur de base de données IBM Informix, principalement la solution proposée par IBM sous le nom de “MACH” – Multi Node Active Cluster for High Availability : Cluster actif multi-nœuds à haute disponibilité.<br/>

            Dans le cas d’environnements de bases de données hétérogènes et d’une exigence d’isolation du serveur de base de données source ainsi que l’échange de données entre les serveurs, nous utilisons respectivement IBM InfoSphere Data Replication (IDR / IIDR) et IBM Infosphere Change Data Capture (CDC). IBM Change Data Capture est une réplication basée sur les journaux qui décharge le serveur de la base de données source afin de répondre aux exigences de haute performance.<br/>

            L’apport de toutes les fonctionnalités High Availability d’Informix (HDR, RSS, SDS, CLR, ER) + IBM InfoSphere Data Replication peut être discuté en formation ou en atelier dédié.<br/>

            Nous vous conseillons de la meilleure façon possible et réalisons tous les types et tailles de solutions IBM Informix High Availability :<br/>
            </p>
            <br/>
            <ul>
                <li>
                IBM Informix Grid
                </li>
                <br/>
                <li>
                High Availability Data Replication (HDR)                </li>
                <br/>
                <li>
                Enterprise Replication(ER / CDR)
                </li>
                <br/>
                <li>
                Remote Standalone Secondary (RSS)
                </li>
                <br/>
                <li>
                SDS (Shared Disk Server)
                </li>
                <br/>
                <li>
                Continuous Log Restore (CLR)
                </li>
                <br/>
                <li>
                Connection-Manager
                </li>
                <br/>
                <li>
                IBM InfoSphere Data Replication / IBM InfoSphere Change Data Capture (CDC)
                </li>
            </ul>
            <br/>
            <br/>
            <br/>
            </Col>
            </Row>
            </Container>
            
        </div>
    )
}

export default HA
