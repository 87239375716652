import React from 'react'
import image from '../../images/contact.jpg'
import Image from 'react-bootstrap/esm/Image'
import './Contact.css'
import { Container, Row, Col} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'
import { Button } from 'react-bootstrap'; 

function Contact() {
    return (
        <div className='contact'>
            <Image src={image} style={{ 'width': '100%', 'height': '400px' }} />
            <h1><b>CONTACTEZ-NOUS</b></h1>
            <hr />
            <br/>
            <p><b>New System Engineering </b>est à votre disposition et à votre écoute si vous avez besoin de plus d'informations, 
             n'hésitez pas à nous contacter .</p>
             <br/><br/><br/><br/>
            <Container>
                <Row>
                    <Col md={6} className='col1'>
                    <h4>Nous Contacter</h4>
                    <center><hr style={{'width':'40px', 'marginTop':'-40px'}}/></center><br/>
                    <p><i class="fas fa-phone me-3"></i> +212 6 63 48 73 49</p><br/>
                    <p><i class="fas fa-envelope me-3"></i>mohamed.afeilal@ns-engineering.ma</p><br/>
                    <p><i class="fas fa-home me-3"></i>Résidence Luxoria Appart 66, Rte de Taddart Casablanca</p><br/>

                    </Col>
                    <Col md={6}>
                    <Form>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridEmail">
                            <Form.Label>Nom</Form.Label>
                            <Form.Control type="lastname" />
                            </Form.Group>

                            <Form.Group as={Col} controlId="formGridPassword">
                            <Form.Label>Prenom</Form.Label>
                            <Form.Control type="firstname"  />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                        <Form.Group as={Col} controlId="formGridAddress1">
                            <Form.Label>E-mail</Form.Label>
                            <Form.Control type="email" />
                        </Form.Group>

                        <Form.Group as={Col} controlId="formGridAddress2">
                            <Form.Label>Tél</Form.Label>
                            <Form.Control />
                        </Form.Group>
                        </Row>
                        <Form.Label>Description</Form.Label>
                        <InputGroup>
                        
                        <FormControl as="textarea" aria-label="With textarea" />
                        
                        </InputGroup>
                        <br/>
                        <Button variant="primary" type="reset">
                            Envoyer
                        </Button>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <br/>
            <br/>
            
                <Container>
                    <Row>
                        <Col>
                        <div class="mapouter"><div class="gmap_canvas">
                <iframe style={{'width':'1300px', 'height':"500px", 'left':'50%'}} id="gmap_canvas" src="https://maps.google.com/maps?q=residence%20luxoria%20casablaca%20maroc&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe>
                
                <a href="https://2piratebay.org"></a>
                </div>
                </div>
                </Col>
                </Row>
                </Container>
            </div>
    )
}

export default Contact
