import React from 'react'

function Produits() {
    return (
        <div id='#produits'>
            <h2>Produits</h2>
        </div>
    )
}

export default Produits
