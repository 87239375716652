import React, { useState, useEffect } from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from '../images/NSE1.png'
import 'bootstrap/dist/css/bootstrap.min.css';

function Navbar() {
    const [click, setClick] = useState(false);
    const [button, setButton] = useState(true);
  
    const handleClick = () => setClick(!click);
    const closeMobileMenu = () => setClick(false);
  
    const showButton = () => {
      if (window.innerWidth <= 960) {
        setButton(false);
      } else {
        setButton(true);
      }
    };
  
    useEffect(() => {
      showButton();
    }, []);
  
    window.addEventListener('resize', showButton);



  return (
    <>

    <nav className='navbar'>
            <div className='navbar-container'>
            <a className="navbar-brand" href="/">
            <img src={logo}  style={{'height':'70px','width':'300px'}}/>
            </a>
              <div className='menu-icon' onClick={handleClick}>
                <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
              </div>
            
              <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                <li className='nav-item'>
                  <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                    ACCUEIL
                  </Link>
                </li>

                <div className='dropdown'>
                <li className='nav-item' ><a href="/database_server" className='nav-links' onClick={closeMobileMenu}>PRODUITS <i class="fas fa-angle-down" style={{'marginLeft':'8px'}}> </i></a>
                <ul className='submenu'>
                  <li>
                    <a  className='sub-links' href="/database_server" onClick={closeMobileMenu} style={{'marginTop':'8px'}}>IBM INFORMIX <i class="fas fa-angle-right" style={{'marginLeft':'8px'}}></i></a>
                      <ul className='submenu2'>
                        <li><a href="/database_server" onClick={closeMobileMenu}>DATABASE SERVER</a></li>
                        <li><a href="/warehouse" onClick={closeMobileMenu}>WAREHOUSE ACCELERATOR</a></li>
                        <li><a href="/informix_4gl" onClick={closeMobileMenu}>4GL</a></li>
                        <li><a href="/genero" onClick={closeMobileMenu}>GENERO</a></li>
                      </ul>
                  </li>
                  

                  <li>
                    <a  className='sub-links' href="/optim" onClick={closeMobileMenu} style={{'marginTop':'4px','padding':'8px','marginLeft':'-8px'}}>IBM INFOSPHERE<i class="fas fa-angle-right" style={{'marginLeft':'8px'}}></i></a>
                    <ul className='submenu2'>
                        <li><a href="/optim" onClick={closeMobileMenu}>OPTIM</a></li>
                        <li><a href="/ibm_guardium" onClick={closeMobileMenu}>GUARDIUM</a></li>
                        <li><a href="/data_replication" onClick={closeMobileMenu}>DATA REPLICATION</a></li>
                      </ul>
                  </li>


                </ul>
              </li>
              </div>
    
                <li className='nav-item'>
                  <Link
                    to='/support'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    SUPPORT
                  </Link>
                </li>

                <div className='dropdown'>
                <li className='nav-item' ><a href="/analyse_et_optimisation" className='nav-links' onClick={closeMobileMenu}>SERVICES <i class="fas fa-angle-down" style={{'marginLeft':'8px'}}> </i></a>
                <ul className='submenu'>


                  <li>
                    <a a className='sub-links' href="/analyse_et_optimisation" onClick={closeMobileMenu} style={{'marginTop':'8px'}}>IBM INFORMIX <i class="fas fa-angle-right" style={{'marginLeft':'8px'}}></i></a>
                      <ul className='submenu2'>
                        <li><a className='sub-links' href="/analyse_et_optimisation" onClick={closeMobileMenu}>ANALYSE DATABASES</a></li>
                        <li><a href="/migration" onClick={closeMobileMenu}>MIGRATION</a></li>
                        <li><a href="/haute_disponibilite" onClick={closeMobileMenu}>HAUTE DISPONIBILITE</a></li>
                        <li><a href="/sauvegarde_restauration" onClick={closeMobileMenu}>SAUVEGARDE / RESTAURATION</a></li>
                        <li><a href="/informix" onClick={closeMobileMenu}>INFORMIX LICENCES</a></li>
                      </ul>
                  </li>
                  <li>
                    <a a className='sub-links' href="/guardium" onClick={closeMobileMenu} style={{'marginTop':'4px','padding':'8px','marginLeft':'-8px'}}>IBM INFOSPHERE<i class="fas fa-angle-right" style={{'marginLeft':'8px'}}></i></a>
                    <ul className='submenu2'>
                        <li><a href="#" onClick={closeMobileMenu}>OPTIM</a></li>
                        <li><a href="/guardium" onClick={closeMobileMenu}>GUARDIUM</a></li>
                      </ul>
                  </li>


                </ul>
              </li>
              </div>

                <li className='nav-item'>
                  <Link
                    to='/formations'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    FORMATIONS
                  </Link>
                </li>
                
                <li className='nav-item'>
                  <Link
                    to='/contact'
                    className='nav-links'
                    onClick={closeMobileMenu}
                  >
                    CONTACT
                  </Link>
                </li> 
                
              </ul>
              
              {/* {button && <Button buttonStyle='btn--outline'>SIGN UP</Button>}*/ }
            </div>
          </nav>
        </>
  )
}

export default Navbar
 