import React from 'react'
import {Card, Carousel, CarouselItem} from 'react-bootstrap'
import './Cardsimg.css'
import { Container, Row, Col,  } from 'react-grid-system';
import logo1 from '../images/logo1.png'
import logo2 from '../images/logo2.png'
import logo3 from '../images/logo3.png'
import logo4 from '../images/logo4.png'
import logo5 from '../images/logo5.jpg'
import logo6 from '../images/logo6.png'
import logo7 from '../images/logo7.png'
import logo8 from '../images/logo8.png'
import logo9 from '../images/logo11.jpg'
import logo10 from '../images/logo9.png'
import logo11 from '../images/logo13.png'
import logo12 from '../images/logo12.png'
import logo13 from '../images/logo17.jpg'
import logo14 from '../images/logo15.png'
import logo15 from '../images/logo16.png'
import logo16 from '../images/logo22.png'
import logo19 from '../images/logo21.png'
import logo20 from '../images/logo23.png'
import logo21 from '../images/logo24.jpg'
import logo22 from '../images/logo25.jpeg'
import logo23 from '../images/logo28.jpg'
import logo24 from '../images/logo26.png'
import logo25 from '../images/logo27.png'

function Cardsimg() {
    return (
        <div className='resp'>
            <h1 style={{
                'textAlign':'center', 
                'padding':'30px', 
                'margin-top': '30px',
                'color': 'rgb(196, 28, 28)',
                'font-size': '30px',
                'text-align': 'center',
                'font-family': 'Nunito Sans sans-serif'}}>
                <b>PARTENAIRES & SOLUTIONS</b></h1>
                
            
                <center><h2 style={{'fontSize':'25px','color':'gray','marginTop':'0px'}}>Solutions IBM</h2></center>
                <Carousel>
                <CarouselItem>
                    <Container className='imgresp'>
                    
                        <Row style={{'marginTop': '50px'}}>
                            <Col md={2} sm={3} xs={4} >
                                <img className="image_responsive"   src={logo1}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/><br/>
                                
                                </Col> 
                            <Col md={2} sm={3} xs={4}> 
                            <img className="image_responsive"   src={logo3}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>  
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo4}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo5}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo6}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                            <Col md={2} sm={3} xs={4}>
                                <img className="image_responsive"   src={logo7}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>                                
                            </Col> 
                            </Row>
                            </Container>
                            </CarouselItem>
                            <CarouselItem>
                                <Container>
                             <Row>
                            <Col md={2} sm={3} xs={4}> 
                            <img className="image_responsive"   src={logo9}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>  
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo10}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                            <Col md={2} sm={3} xs={4}>
                                <img className="image_responsive"   src={logo19}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                                
                                </Col> 
                            <Col md={2} sm={3} xs={4}>
                            <img className="image_responsive"   src={logo20}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> 
                            <Col md={2} sm={3} xs={4}> 
                            <img className="image_responsive"   src={logo21}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> 
                            <Col md={2} sm={3} xs={4}> 
                            <img className="image_responsive"   src={logo22}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> 
                        </Row>
                        
                    </Container>
                    </CarouselItem>
                    </Carousel>
                    <br/><br/>
                <center><h2 style={{'fontSize':'25px','color':'gray','marginTop':'0px'}}>Solutions SAP</h2></center>
                    <Container>
                        <Row style={{'marginTop': '50px'}}>
                        <Col md={2} sm={3} xs={4}>
                            <img className="image_responsive"   src={logo8}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> 
                            <Col md={2} sm={3} xs={4}>
                                <img className="image_responsive"   src={logo13}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                                
                                </Col> 
                                {/* <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo11}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> */}
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo12}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                            <Col md={2} sm={3} xs={4}>
                            <img className="image_responsive"   src={logo14}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> 
                            <Col md={2} sm={3} xs={4}> 
                            <img className="image_responsive"   src={logo15}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>  
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo16}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                        </Row>
                    </Container>

                    <br/><br/>
               
                <center><h2 style={{'fontSize':'25px','color':'gray','marginTop':'0px'}}>Solutions HCL</h2></center>
                    <Container>
                        <Row style={{'marginTop': '50px'}}>
                        
                            <Col md={2} sm={3} xs={4}>
                            <img className="image_responsive"   src={logo23}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col> 
                            <Col md={2} sm={3} xs={4}> 
                            <img className="image_responsive"   src={logo24}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>  
                            <Col md={2} sm={3} xs={4}>  
                            <img className="image_responsive"   src={logo25}  class="img-thumbnail" style={{ 'width': '150px', 'height':'150px' }}/>
                            </Col>
                        </Row>
                    </Container>
                    <br/><br/>
        </div>
    )
}

export default Cardsimg
