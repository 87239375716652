import React from 'react'
import { Container, Row, Col } from 'react-grid-system'
import { Image } from 'react-bootstrap'
import image from '../../images/schéma_guardium.png'
import './Guardium.css'
import image1 from '../../images/GDPR.jpg'
import card from '../../images/img2.jpg'

function Guardium() {
    return (
        <div className='grad'>
            <center>
            <Image src={card} style={{ 'width': '100%', 'height': '400px' }} /></center>
            <br/>
            <h1><b>GUARDIUM</b></h1>
            <hr />
            <br/>
            <Container>
                <Row>
                    <Col md={12} sm={12} xs={12}>
                    <div className="arrow-right" ></div><h5>Application des exigences réglementaires</h5><br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>Notre mission consiste à :</p>
                    <ul>
                        <li>
                        Créer un référentiel d’audit centralisé unique et sécurisé pour un grand nombre de systèmes et de bases de données hétérogènes.
                        </li>
                        <li>
                        Automatisez l’ensemble du processus d’audit de conformité, y compris la création et la distribution de rapports, ainsi que la capture de commentaires et de signatures. 
                        </li>
                    </ul>
                    </Col>
                </Row>
            </Container>
            <br/><br/>
            <Container>
                <Row>
                    <Col md={6}  sm={12} xs={12}>
                    <br/><br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}><b>Exemple d’implémentation :</b><br/>
                    Il ne s’agit en aucun cas d’une liste exhaustive, les réglementations suivantes concernent l’accès aux données financières ou sensibles :
                    </p>
                    </Col>
                    <Col md={6}  sm={12} xs={12}>
                    <br/>
                    <img src={image} />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                    <br/><br/>
                    <ul>
                        <li>
                        Sarbanes-Oxley (SOX)
                        </li>
                        <li>Norme de sécurité des données de l’industrie des cartes de paiement (PCI)</li>
                        <li>Gramm – Leach – Bliley</li>
                        <li>Directive de l’Union européenne 95/46 / CE</li>
                        <li>Loi sur la transférabilité et la responsabilité de l’assurance maladie (HIPAA)</li>
                        <li>ISO 27002</li>
                    </ul>
                    </Col>
                </Row>
            </Container>
            <br/><br/>
            <Container>
                <Row>
                    <Col md={6}  sm={12} xs={12}>
                    <br/>
                    <div className="arrow-right" ></div><h5>Application des exigences réglementaires – GDPR</h5><br/><br/>
                    <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>Approche simple en 5 étapes pour la conformité GDPR<br/>
                    La conformité GDPR est complexe, mais également ouverte : elle ne dit pas en détail comment respecter ces obligations ni la limite à atteindre.<br/></p>
                    </Col>
                    <Col md={6}  sm={12} xs={12}>
                        <img src={image1} />
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                    <br/>
                    <ol>
                        <li style={{'font-family': 'Source Sans Pro, sans-serif'}}><b>Evaluation de la situation </b>et chemin à suivre</li><br/>
                        <li style={{'font-family': 'Source Sans Pro, sans-serif'}}><b>Conception de l’approche</b> Elaboration d’un plan pour la collecte, l’utilisation et le stockage des données. Mise en place d’une architecture qui équilibrent les risques et les objectifs commerciaux</li><br/>
                        <li style={{'font-family': 'Source Sans Pro, sans-serif'}}><b>Transformation des pratiques</b> et amélioration des process. Comprendre que les données sont précieuses pour l’entreprise. Prendre des Mesures Techniques et Organisationnelles.</li><br/>
                        <li style={{'font-family': 'Source Sans Pro, sans-serif'}}><b>Exécution de votre programme</b> Désormais, vous inspectez continuellement vos données, surveillez l’accès aux données personnelles, testez votre sécurité, utilisez la confidentialité et la sécurité selon les principes de conception et purgez les données inutiles. Cadre opérationnel GDPR en place</li><br/>
                        <li style={{'font-family': 'Source Sans Pro, sans-serif'}}><b>Conformité aux exigences du GDPR</b> Maintenant, vous remplissez les exigences pour l’accès aux données, la modification, la suppression et le transfert. Vous êtes également prêt pour les audits de vos activités et des personnes concernées en cas de violation de données.</li><br/>
                    </ol>
                    </Col>
                </Row>
            </Container>
            <br/><br/><br/><br/>
        </div>
    )
}

export default Guardium
