import React from 'react'
import { Container, Row, Col,  } from 'react-grid-system';
import image1 from '../images/pres2.jpg'
import './Presentation2.css'
function Presetation2() {
    return (
        <div className='pres2'>
           <Container className='cont2'>
               <Row>
                   <Col md={5}>
                       <br/>
                       <br/>
                        <img src={image1} style={{'width':'100%', 'height':'300px'}}/>
            
                    </Col>
                    <Col md={1}>
                    </Col>
                    <Col md={6} className='text'>
                        <h1></h1>
                        <p>New System Engineering est une société d’ingénierie des systèmes 
                        d’information spécialisée dans la Gouvernance des Données, Intégration 
                        des solutions Big-Data  et Intégration des API.
                        New System Engineering est constituée par des experts dans la gouvernance de 
                        l’information, avec une forte expérience dans la sécurité et l’exploitation des données.
                        Notre mission est de prendre en charge toutes les disciplines, les technologies et les solutions
                        utilisées pour gérer l’information au sein d’une entreprise afin de supporter toutes les exigences
                        commerciales et juridiques. Notre activité englobe un large éventail de sujet couvrant la qualité 
                        de l’information, la mise en place d’un référentiel centralisé, la protection et la sécurité de l’information 
                        et la gestion du cycle de vie de l’information.

</p>
                    </Col>
                </Row>
            </Container> 
        </div>
    )
}

export default Presetation2
