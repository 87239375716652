import React from 'react'
import Image from 'react-bootstrap/Image'
import { Container, Row, Col } from 'react-grid-system'
import img1 from '../../images/optim1.png'
import img2 from '../../images/optim2.png'

function Optim() {
    return (
        <div>
            <hr/>
            <br/>
            <h1><b>IBM INFOSPHERE OPTIM – MODULES</b></h1><br/>
            <hr />  
            <br/>
            <br/>
            <center>
          <Image src={img1} style={{ 'width': '600px', 'height': '400px' }} /><br/></center>
            
            <Container>
              <Row>
                  <Col>
                  <div className="arrow-right" ></div><h5>Data Archiving</h5><br/>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                  Optim dispose de fonctionnalités spécialement conçues pour archiver les données inactives à partir des bases de données de production afin de les maintenir au plus bas niveau et de les utiliser efficacement. Les données archivées sont déplacées vers des fichiers plats immuables où elles sont toujours facilement accessibles par l’entreprise, les données archivées sont retirées du chemin critique où elles pourraient avoir un impact négatif sur les performances et la disponibilité des systèmes de production.<a href='#' style={{'color':'red', 'textDecoration':'underline'}}> Plus d’informations … </a>
                  </p>
                  </Col>
                </Row>
            </Container>
            <br/>
            <Container>
              <Row>
                  <Col>
                  <div className="arrow-right" ></div><h5>Data Masking</h5><br/>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                  Optim Data Privacy est la principale solution de masquage de données depuis de nombreuses années. Il remplace automatiquement les données sensibles (PII, PCI, PHI, etc.) par des valeurs factieuses qui sont toujours valables pour les tests, mais à l’abri des pirates informatiques ou d’autres personnes susceptibles de vouloir les voler.                  </p>
                  </Col>
                </Row>
            </Container>
            <br/>
            <Container>
              <Row>
                  <Col>
                  <div className="arrow-right" ></div><h5>Data Retirement</h5><br/>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                  Optim a également la possibilité de retirer complètement une base de données, généralement lorsqu’une application a été mise hors service, ce qui consiste essentiellement à archiver 100% des données d’une base de données. Le client maintient toujours l’accès à ses données sans frais de maintenance des serveurs, des bases de données et des licences d’applications habituellement requises pour permettre d’accéder aux données historiques importantes.                  </p>
                  </Col>
                </Row>
            </Container>
            <Container>
              <Row>
                  <Col>
                  <div className="arrow-right" ></div><h5>Data Testing</h5><br/>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                  Au-delà de l’archivage, Optim fournit une fonctionnalité de sous-ensemble qui permet aux clients de créer des sous-ensembles simples de bases de données de production à des fins de test et de développement. Cela permet non seulement d’économiser beaucoup d’espace de stockage, mais également de mettre en œuvre des approches de développement Agile afin de réduire le temps nécessaire pour bénéficier de nouvelles fonctionnalités logicielles et créer des applications de qualité supérieure. La fonctionnalité de sous-ensemble Optim est le plus souvent fournie avec la solution de masquage de données Optim, appelée Optim Data Privacy.<a href='#' style={{'color':'red','textDecoration':'underline'}}> Plus d’informations … </a></p>
                  </Col>
                </Row>
            </Container>
            <br/>
            <Container>
              <Row>
                  <Col md={5}>
                  <div className="arrow-right" ></div><h5>Test Data Fabrication – Test Data Orchestrator</h5><br/>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                  Aujourd’hui, IBM annonce de nouvelles fonctionnalités Optim qui peuvent encore vous aider à améliorer la gestion des données : Optim TDM pour DevOps, Orchestration de la préparation des données de test. En plus de Optim Archive et Retirement on Cloud.                  </p>
                  </Col>
                </Row>
            </Container>
            <br/>
            <Container>
              <Row>
                  <Col md={6}>
                  <div className="arrow-right" ></div><h5>Architecture</h5><br/>
                  <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                  La Figure ci-dessous fournit une représentation de la prise en charge étendue des technologies d’entreprise fournies par les solutions Optim (Croissance des données, Retrait des données et des applications, Gestion des données de test et Confidentialité des données) prennent en charge à la fois les applications prédéfinies et les applications personnalisées.                  </p>
                  </Col>
                  <Col md={6}>
                      <img src={img2}  style={{'width':'600px','height':'300px'}}/>
                      <br/><br/>
                  </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Optim
