import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import { Container, Row , Col} from 'react-bootstrap';
import logo from '../images/logonse.png'

function Footer() {
  return (
<footer class="text-center text-lg-start bg-light text-muted">
  <section
    class="d-flex justify-content-center justify-content-lg-between p-4 border-bottom"
  >
    <div class="me-5 d-none d-lg-block">
      <span>Rejoignez-nous sur nos réseaux sociaux !</span>
    </div>
    <div>
      <a href="https://web.facebook.com/newsystemengineering" class="me-4 text-reset">
        <i class="fab fa-facebook-f" style={{'size':'30px'}}></i>
      </a>
      <a href="https://ns-engineering.ma" class="me-4 text-reset">
        <i class="fab fa-google"></i>
      </a>
      <a href="https://ma.linkedin.com/company/new-system-engineering" class="me-4 text-reset">
        <i class="fab fa-linkedin"></i>
      </a>
    </div>
  </section>
  <section class="">
    <div class="container text-center text-md-start mt-5">
      <div class="row mt-3">
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4">
            <a href='/'>
            <img src={logo} style={{'marginLeft':'-80px'}} />
            </a>
          </h6>
          <p>
          </p>
        </div>
        
        <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-4">
          <h6 class="text-uppercase fw-bold mb-4" style={{'color':'rgb(196, 28, 28)'}}>
            Liens Rapides
          </h6>
          <p>
            <a href="/" class="text-reset" >Accueil</a>
          </p>
          <p>
            <a href="/support" class="text-reset">Support</a>
          </p>
          <p>
            <a href="/database_server" class="text-reset">Produits</a>
          </p>
          <p>
            <a href="/formations" class="text-reset">Formations</a>
          </p>
          <p>
            <a href="/analyse_et_optimisation" class="text-reset">Services</a>
          </p>
        </div>
        <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
          <h6 class="text-uppercase fw-bold mb-4" style={{'color':'rgb(196, 28, 28)'}}>
            Contact
          </h6>
          <p><i class="fas fa-home me-3"></i>Résidence Luxoria Appart 66, Rte de Taddart Casablanca</p>
          <p>
            <i class="fas fa-envelope me-3"></i>
            mohamed.afeilal@ns-engineering.ma
          </p>
          <p><i class="fas fa-phone me-3"></i> +212 663 48 73 49</p>
        </div>
      </div>
    </div>
  </section>
  {/* <div class="text-center p-4" style={{"background-color": "rgba(0, 0, 0, 0.05)"}}>
    © 2021 Copyright:
    <a class="text-reset fw-bold" href="http://ns-engineering.ma/">New System Engineering</a>
  </div> */}
</footer>
  );
}

export default Footer;














