import React from 'react'
import Image from 'react-bootstrap/Image'
import { Container, Row, Col } from 'react-grid-system'
import img from '../../images/ibmg.png'
import tab from '../../images/tab1.png'

function Ibmguardium() {
    return (
        <div className='ibmg'>
             <hr/>
            <br/>
            <h1><b>IBM GUARDIUM</b></h1>
            <hr />  
            <br/>
            <br/>            
            <Container>
              <Row>
                  <Col md={7} sm={12} xm={12}>
                      <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                        IBM Guardium empêche les fuites de bases de données, d’entrepôts de données et d’environnements Big Data tels que Hadoop, garantit l’intégrité des informations et automatise les contrôles de conformité dans des environnements hétérogènes.<br/>
                        Il protège les données structurées et non structurées dans les bases de données, les environnements Big Data et les systèmes de fichiers contre les menaces et garantit la conformité.<br/>
                        Il fournit une plate-forme évolutive qui permet une surveillance continue du trafic de données structurés et non structurés ainsi que l’application de politiques pour l’accès aux données sensibles à l’échelle de l’entreprise.<br/>
                        Un référentiel d’audit sécurisé et centralisé associé à une plate-forme d’automatisation du workflow intégrée rationalise les activités de validation de la conformité dans une grande variété de mandats.<br/></p>
                  </Col>
                  <Col md={5} sm={12} xm={12}>
                      <img src={img} />
                  </Col>
                  <Col >
                      <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>Un référentiel d’audit sécurisé et centralisé associé à une plate-forme d’automatisation du workflow intégrée rationalise les activités de validation de la conformité dans une grande variété de mandats.<br/>
                        IBM Guardium tire parti de l’intégration avec des solutions de gestion informatique et d’autres solutions de gestion de la sécurité pour fournir une protection complète des données dans toute l’entreprise.<br/>
                        IBM Security Guardium est conçu pour aider à protéger les données critiques. Guardium est une plateforme complète de protection des données qui permet aux équipes de sécurité d’analyser automatiquement ce qui se passe dans les environnements sensibles (bases de données, entrepôts de données, plates-formes Big Data, environnements cloud, systèmes de fichiers, etc.) des menaces internes et externes, et s’adapte parfaitement aux changements informatiques susceptibles d’avoir un impact sur la sécurité des données. Guardium garantit l’intégrité des informations dans les centres de données et automatise les contrôles de conformité.<br/></p>
                  </Col>
                </Row>
            </Container>

            <Container>
                <Row>
                    <Col >
                        <div className="arrow-right" ></div><h5>La solution IBM Security Guardium est proposée en deux versions:</h5><br/>
                        <ul>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Surveillance de l’activité de la base de données IBM Security Guardium (DAM)</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif'}}>Surveillance de l’activité des fichiers (FAM) d’IBM Security Guardium – Utilisez la surveillance d’activité de fichier Guardium pour étendre les fonctions de surveillance aux serveurs de fichiers.</li>
                        </ul>
                        <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>IBM Guardium offrent une solution simple et robuste pour empêcher les fuites de données à partir des bases de données et des fichiers, ce qui contribue à garantir l’intégrité des informations dans le centre de données et à automatiser les contrôles de conformité.</p>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col>
                    <div className="arrow-right" ></div><h5>Les produits IBM Guardium peuvent vous aider à :</h5><br/>
                        <ul>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Localisez automatiquement les bases de données et découvrez et classifiez les informations sensibles qu’elles contiennent.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Évaluer automatiquement les vulnérabilités de base de données et les failles de configuration.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Assurez-vous que les configurations sont verrouillées après la mise en œuvre des modifications recommandées.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Permettre une haute visibilité à un niveau granulaire dans les transactions de base de données impliquant des données sensibles.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Suivre les activités des utilisateurs finaux qui accèdent indirectement aux données via des applications d’entreprise.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Surveiller et appliquer un large éventail de stratégies, y compris l’accès aux données sensibles, le contrôle des modifications de la base de données et les actions des utilisateurs privilégiés.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Créer un référentiel d’audit centralisé unique et sécurisé pour un grand nombre de systèmes et de bases de données hétérogènes.</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Automatisez l’ensemble du processus d’audit de conformité, y compris la création et la distribution de rapports, ainsi que la capture de commentaires et de signatures.</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row>
                    <Col md={6}sm={12} xm={12}>
                    <br/>
                    <br/>
                    <br/>
                        <p style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>La solution IBM Security Guardium : Deux types de déploiement</p><br/>
                        <ul>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Surveillance de l’activité de la base de données IBM Security Guardium (DAM) et l’activité des fichiers (FAM)</li>
                            <li style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Évaluation de la vulnérabilité (VA).</li>
                        </ul>
                        <p style={{'font-family': 'Source Sans Pro, sans-serif', 'textAlign':'justify'}}>Ces principaux types sont divisés en deux types : Basic et Avancé,</p><br/>

                    </Col>
                    <br/>
                    <Col md={6} sm={12} xm={12}>
                    <img src={tab} style={{'width': '500px'}}/>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default Ibmguardium
