import React from 'react'
import card from '../../images/analy.jpg'
import Image from 'react-bootstrap/Image'
import { Container,Row, Col } from 'react-bootstrap';
import './Informix.css'

function Informix() {
    return (
        <div >
            <Image src={card} style={{ 'width': '100%', 'height': '400px' }} />
            <br/>
            <br/>
            <br/>
            <h1><b>INFORMIX LICENCES</b></h1>
            <hr />
            <br/>
            
            <Container>
                <Row>
                    <Col classeName='informix'>
                    <h3><b>OPTIMISATION DES LICENCES</b></h3><br/>
            <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
            La variété des éditions de bases de données et des types de licence IBM Informix existants permet à l’utilisateur d’IBM Informix de mettre sous licence leur logiciel de base de données utilisé de manière financièrement efficace. Avoir beaucoup d’options de licence est définitivement positif.<br/><br/>

            D’un autre côté, les types de licences et les options existantes peuvent compliquer le choix de la solution de licence la plus adaptée à votre activité. Mais il vaut la peine d’examiner de plus près les aspects techniques, financiers et juridiques des opportunités données afin d’éviter les licences incorrectes.<br/><br/>

            Une licence incorrecte peut entraîner des coûts supplémentaires tels que des frais de pénalité ou des coûts d’entretien croissants. Dans les deux scénarios, vous paierez plus que ce dont vous avez vraiment besoin. L’éveil brutal se produira dans le cas d’un test de conformité exécuté par des sociétés d’audit assignées par IBM.<br/><br/>

            Dans ce cas, non seulement les licences Informix doivent être achetées au prix catalogue, mais d’autres pénalités importantes doivent être payées. En outre, vous devez penser aux conséquences juridiques. Les présidents et les directeurs généraux sont tenus personnellement responsables de la non régularisaion.<br/><br/>

            Une évaluation de licence IBM Informix est logiquement possible à tout moment:
            </p>
            <br/>
            <ul>
                <li>
                Pendant l’exploitation ‘RUN’ : réexamen des changements
                </li>
                <br/>
                <li>
                Au moment de l’achat : économies de coûts à long terme
                </li>
                <br/>
                <li>
                Avant les changements : rapport qualité prix
                </li>
            </ul>
            <br/>
            <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
            En commune coordination, nous vous conseillons de mettre votre base de données IBM Informix sous licence de la manière la plus efficace et la plus rentable afin d’économiser des coûts d’acquisition et d’exploitation. Cela se fera individuellement et bénéficiera rapidement de contacts IBM éprouvés.<br/><br/>

            Un contrôle de conformité Informix tient en considération, à titre d’exemple les points suivants:
            </p>
            <ul>
                <li>
                Éditions Informix</li>
                <br/>
                <li>
                Types de licences
                </li>
                <br/>
                <li>
                Contrats spéciaux
                </li>
                <br/>
                <li>
                CPU
                </li>
                <br/>
                <li>
                Sockets
                </li>
                <br/>
                <li>
                Chemin d’accès à la base de données
                </li>
                <br/>
                <li>
                Technologies de virtualisation …
                </li>
                </ul>
                <br/><br/>
                <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                Sur la base d’une liste de contrôle et des conditions réellement valables des contrats de licence IBM, nous garantissant à vos licences IBM Informix l’exactitude, la facilité d’utilisation et la rentabilité, nous pouvons aussi vous proposer des optimisations.<br/><br/>
                </p>
                <h3><b>LICENSING</b></h3><br/>
                <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                IBM propose pour le serveur de base de données Informix des modèles de licence basés sur les utilisateurs et sur les processeurs. Les types de licences ont été modifiés au fil des années et seront également flexibles dans le futur. Ce processus reflète le développement technique ainsi que la stratégie de l’éditeur.<br/><br/>
                </p>
                <div className="arrow-right" ></div><h5>Authorized User Single Install (AUSI)</h5><br/>
                <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                Ce mode de licensing actuellement disponible, représente l’ancien mode : utilisateur autorisé (AU) – et l’utilisateur enregistré (RU).<br/><br/>
                A considérer pour la licence:
                </p>
                <ul>
                <li>
                Une personne connue par son nom
                </li>
                <br/>
                <li>
                Ou une application spécifique
                </li>
                <br/>
                <li>
                Qui peut accéder à une application
                </li>
                <br/>
                <li>
                Par l’utilisation de connexions simultanées illimitées
                </li>
                <br/>
                <li>
                Indépendant du fait si la personne ou l’application est active
                </li>
                </ul>
                <br/><br/>
                <div className="arrow-right" ></div><h5>Processor Value Unit (PVU) and Limited Use Socket (LUS)</h5><br/>
                <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                    Jusqu’au 31/12/2017, les modèles de licence basés sur les processeurs étaient représentés par les métriques de licence de l’unité de valeur du processeur (PVU) et du socket à usage limité (LUS). (Jusqu’à ce jour, l’option LUS n’est pas encore disponible).<br/>
                    A considérer pour la licence:</p>
                    <ul>
                <li>
                La technologie du processeur utilisée (fournisseur, marque, type de processeur)
                </li>
                <br/>
                <li>
                La puissance du processeur décrite dans les unités de valeur (VU)
                </li>
                <br/>
                <li>
                Le nombre de sockets de processeur utilisées pour Informix
                </li>
                </ul>
                <p style={{'font-family': 'Source Sans Pro, sans-serif'}}>
                    <b style={{'color':'rgb(196, 28, 28)'}}>Important !</b><br/>
                    si vous exécutez un cluster typique Informix pour la Haute Disponibilité (par exemple High Replication Data Replication) composé de deux nœuds de cluster au minimum et assurant un basculement en cas d’urgence, vous n’avez pas besoin d’acquérir une licence supplémentaire pour le second nœud de cluster. Seulement si vous accédez au deuxième nœud de cluster pendant les heures de service normal, vous devez acheter des licences Informix supplémentaires. Le type et la portée des licences requises peuvent être différents dans ce cas.<br/><br/>
                    Cet aperçu met en évidence les faits les plus importants. Pour plus d’informations sur les licences et les opportunités d’optimisation dans votre environnement, contactez-nous directement.
                </p>
            </Col>
            </Row>
            </Container>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
    )
}

export default Informix
