import React from 'react';
import './Cards.css';
import CardItem from './CardItem';
import card1 from '../images/card1.jpg';
import card2 from '../images/migration.jpg';
import card3 from '../images/sauvegarde.jpeg';
import card4 from '../images/informix.jpg';
import card5 from '../images/car1.jpg';
import card6 from '../images/gradium.jpg';

function Cards() {
  return (
    <div id='serv'>
    <div className='cards' >
      <h1><b>NOS SERVICES</b></h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={card1}
              text='ANALYSE ET OPTIMISATION DES PERFORMANCES'
              path='/analyse_et_optimisation'
            />
            <CardItem
              src={card2}
              text='MIGRATION DE DONNEES'
              path='/migration'
            />
            <CardItem
              src={card3}
              text='HAUTE DISPONIBILITE (HA)'
              path='/haute_disponibilite'
            />
           
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={card4}
              text='INFORMIX LICENCES'
              path='/informix'
            />
            <CardItem
              src={card5}
              text='SAUVEGARDE / RESTAURATION'
              
              path='/sauvegarde_restauration'
            />
            <CardItem
              src={card6}
              text='GUARDIUM'
              
              path='/guardium'
            />
          </ul>
        </div>
      </div>
    </div>
    </div>
  );
}

export default Cards;
