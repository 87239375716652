import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Accueil';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Produits from './components/pages/Produits';
import Formations from './components/pages/Formations';
import Support from './components/pages/Support';
import Contact from './components/pages/Contact';
import Accueil from './components/pages/Accueil'
import 'bootstrap/dist/css/bootstrap.min.css';
import ScrollButton from './components/ScrollButton';
import Footer from './components/Footer';
import Analyse from './components/pages/Analyse';
import Migration from './components/pages/Migration';
import HA from './components/pages/HA';
import Informix from './components/pages/Informix';
import Sauvegardage from './components/pages/Sauvegardage';
import Guardium from './components/pages/Guardium';
import Optim from './components/pages/Optim';
import Ibmguardium from './components/pages/Ibmguardium';
import Datareplication from './components/pages/Datareplication';
import Dataserver from './components/pages/Dataserver';
import Iwa from './components/pages/Iwa';
import Informix4gl from './components/pages/Informix4gl';
import Genero from './components/pages/Genero';
function App() {
  return (
    <>
    
      <Router>
        
      <Navbar />
        <Switch>
        
          <Route path='/' exact component={Accueil} />
          <Route path='/produits' component={Produits} />
          <Route path='/support' component={Support} />
          <Route path='/formations' component={Formations} />
          <Route path='/Contact' component={Contact} />
          <Route path='/analyse_et_optimisation' component={Analyse} />
          <Route path='/migration' component={Migration} />
          <Route path='/haute_disponibilite' component={HA} />
          <Route path='/informix' component={Informix} />
          <Route path='/sauvegarde_restauration' component={Sauvegardage} />
          <Route path='/guardium' component={Guardium} />
          <Route path='/optim' component={Optim} />
          <Route path='/ibm_guardium' component={Ibmguardium} />
          <Route path='/data_replication' component={Datareplication} />
          <Route path='/database_server' component={Dataserver} />
          <Route path='/warehouse' component={Iwa} />
          <Route path='/informix_4gl' component={Informix4gl} />
          <Route path='/genero' component={Genero} />
        </Switch>
        <ScrollButton/>
        <Footer />
      </Router>
    </>
  );
}

export default App;
